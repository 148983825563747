import { IPartialLessonAddDTO, ILesson } from 'apps/early-stage-office/src/app/core/models/lesson.interface';
import { IGradeColumnAddDTO, IGradeSemesterMonth } from 'apps/early-stage-office/src/app/core/models/grade.interface';

export class GetClassRegisterGroup {
    public static readonly type = '[ClassRegister] GetClassRegisterGroup';
    constructor(public payload: { id: number }) {}
}

export class ClearLessons {
    public static readonly type = '[ClassRegister] ClearLessons';
    constructor() {}
}

export class LoadLessons {
    public static readonly type = '[ClassRegister] LoadLessons';
    constructor() {}
}

export class LoadLessonsIfEmpty {
    public static readonly type = '[ClassRegister] LoadLessonsIfEmpty';
    constructor() {}
}

export class UpdateLesson {
    public static readonly type = '[ClassRegister] LoadLesson';
    constructor(public payload: { id: number; lesson: IPartialLessonAddDTO; groupId?: number }) {}
}

export class StartLesson {
    public static readonly type = '[ClassRegister] StartLesson';
    constructor(public payload: { id: number }) {}
}

export class ReloadCurrentLesson {
    public static readonly type = '[ClassRegister] ReloadCurrentLesson';
    constructor(public payload?: { id: number }) {}
}

export class SetCurrentLesson {
    public static readonly type = '[ClassRegister] SetCurrentLesson';
    constructor(public payload: { lesson: ILesson }) {}
}

export class AddGrades {
    public static readonly type = '[ClassRegister] AddGrades';
    constructor(public payload: { gradeColumn: IGradeColumnAddDTO }) {}
}

export class DeleteGradeColumn {
    public static readonly type = '[ClassRegister] DeleteGradeColumn';
    constructor(public payload: { id: number }) {}
}

export class GetGrades {
    public static readonly type = '[ClassRegister] GetGrades';
    constructor() {}
}

export class ClearGrades {
    public static readonly type = '[ClassRegister] ClearGrades';
    constructor() {}
}

export class SetCurrentGradesMonth {
    public static readonly type = '[ClassRegister] SetCurrentGradesMonth';
    constructor(public payload: { currentGradesMonth: IGradeSemesterMonth }) {}
}

export class HideNavigation {
    public static readonly type = '[ClassRegister] HideNavigation';
    constructor(public payload: { hide: boolean }) {}
}
