import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy, ViewRef, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISimplifiedGroup } from 'apps/early-stage-office/src/app/core/models/group.interface';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor, NgStyle, AsyncPipe } from '@angular/common';

@Component({
    selector: 'es-groups-badges',
    templateUrl: './groups-badges.component.html',
    styleUrls: ['./groups-badges.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, NgStyle, AsyncPipe],
})
export class GroupsBadgesComponent implements OnInit, OnDestroy {
    @Input() public groupsSource: Observable<ISimplifiedGroup[]>;
    @Input() public emptyGroupsCount: number = 5;

    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private cd: ChangeDetectorRef) {}

    public ngOnInit() {
        this.groupsSource.pipe(takeUntil(this.destroy$)).subscribe(s => {
            setTimeout(() => {
                if (!(this.cd as ViewRef).destroyed) {
                    this.cd.detectChanges();
                }
            });
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.cd.detach();
    }

    public createArray(length: number): number[] {
        return new Array(length);
    }
}
