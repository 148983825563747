<div class="group-badges" *ngIf="groupsSource | async as groups">
    <div class="group-badges__content">
        <ng-container *ngFor="let item of groups">
            <a [routerLink]="['', { outlets: { menu: null, primary: 'edziennik/' + item.id } }]" class="group-badge">
                <div class="group-badge__icon" [ngStyle]="{ 'background-color': item.color?.background || '#f2f2f2' }">
                    {{ item.realization?.names?.icon }}
                </div>
                <p class="group-badge__title">
                    <span>{{ item.realization?.names?.short }}</span>
                    <span class="truncate">{{ item.name }}</span>
                </p>
            </a>
        </ng-container>

        <ng-container *ngIf="!groups.length">
            <span class="group-badge" *ngFor="let item of createArray(emptyGroupsCount); let i = index">
                <div class="group-badge__icon" [ngStyle]="{ 'background-color': '#f2f2f2' }">{{ i + 1 }}.{{ i + 1 }}</div>
            </span>
        </ng-container>
    </div>

    <div class="group-badges__text" *ngIf="!groups.length || groups.length === 0">Tu zobaczysz grupy z dziennikiem, jak tylko zostaną Ci przypisane</div>
</div>
